import { Props } from './types';

export function LinkMenuItemSocial({ mailto, name, url }: Props) {
  return (
    <a
      href={mailto ? `mailto:${mailto}` : url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {name}
    </a>
  );
}
