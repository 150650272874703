import styled from 'styled-components';

const toggleSize = 28;

const raysLoop = (callback: (index: number) => string) => {
  let css = '';
  for (let i = 1; i <= 8; i += 1) {
    css += `
      &:nth-child(${i}) {
        ${callback(i)}
      }
    `;
  }
  return css;
}

export const Styles = styled.nav`
  @media (max-width: 767px) {
    background-color: var(--cBackground);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: var(--transMed);
    width: 100%;
    z-index: 2;

    &.open {
      transform: translateX(0%);
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  ul {
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    text-transform: capitalize;

    li {
      font-family: 'Playfair Display';
      font-size: 1.15rem;
      font-weight: 300;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 2rem;
    }

    @media (min-width: 768px) {
      ul {
        align-items: center;

        li {
          justify-content: flex-end;
          padding-left: 2rem;
        }
      }
    }
  }

  #theme-checkbox {
    + label {
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: ${toggleSize}px;
      position: relative;
      width: ${toggleSize}px;
  
      * {
        box-sizing: border-box;
      }
  
      .bg {
        background-color: var(--cPrimary);
        border-radius: 50%;
        border: 1px solid var(--cBackground);
        height: ${toggleSize}px;
        transition: var(--transMed);
        width: ${toggleSize}px;
      }
  
      .circle {
        background-color: var(--cBackground);
        border-radius: 50%;
        border: 2px solid transparent;
        height: ${toggleSize}px;
        margin-left: 25%;
        margin-top: -125%;
        transition: var(--transMed);
        width: ${toggleSize}px;
      }
  
      .inner {
        border-radius: 50%;
        height: 100%;
        overflow: hidden;
        width: 100%;
      }
      
      .rays {
        left: 0;
        position: absolute;
        top: 0;
        
        .ray {
          background-color: var(--cPrimary);
          height: ${toggleSize * 0.1}px;
          left: ${toggleSize * 0.475}px;
          opacity: 0;
          position: absolute;
          top: ${toggleSize * 0.5}px;
          transform-origin: ${toggleSize * 0.025}px 0;
          transition: var(--transMed);
          width: ${toggleSize * 0.05}px;
  
          ${raysLoop((i) => `
            transform: rotate(${(-45 * i) - 135}deg) translateY(${toggleSize * 0.5}px);
          `)}
        }
      }
  
      &:hover {
        .bg {
          background-color: var(--cTertiary);
        }

        .circle {
          transform: translateX(-6.25%) translateY(6.25%);
        }
      }
    }

    &:checked {
      + label {
        .circle {
          background-color: var(--cPrimary);
          border-color: var(--cSecondary);
          border-color: var(--cBackground);
          transform: translateX(-25%) translateY(25%);
        }
  
        .rays .ray {
          opacity: 1;
  
          ${raysLoop((i) => `
            transform: rotate(${(-45 * i) - 135}deg) translateY(${toggleSize * .6}px);
            transition: all ${.3 + (i * .3)}s;
          `)}
        }
  
        &:hover {
          .circle {
            background-color: var(--cTertiary);
          }

          .rays .ray {
            background-color: var(--cTertiary);

            ${raysLoop((i) => `
              transform: rotate(${(-45 * i) - 135}deg) translateY(${toggleSize * .65}px);
            `)}
          }
        }
      }
    }
  }

`;
