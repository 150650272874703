import { useTranslation } from 'react-i18next';

// Works as long as we have less than 8 quotes.
export const useQuote = () => {
  const { t } = useTranslation();
  const quotes = t('quotes', { returnObjects: true });
  const day = new Date().getDay();
  const index = day % quotes.length;
  return quotes[index];
};
