import { useTranslation } from 'react-i18next';

import { useContextMenu } from '../../constants/context';

import { Styles } from './styled';

export function Burger() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useContextMenu();

  const toggleNav = () => {
    setIsOpen((state) => !state);
  };

  return (
    <Styles
      className={isOpen ? 'open' : 'closed'}
      onClick={toggleNav}
      type="button"
    >
      {t('component.menu.label')}
      <div>
        <span />
        <span />
        <span />
      </div>
    </Styles>
  )
}
