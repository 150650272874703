import { useMemo } from 'react';

export function Age() {
  const age = useMemo(() => {
    const date = new Date().getFullYear();
    const yearOfBirth = 1995;
    return date - yearOfBirth;
  }, []);
  return age;
}
