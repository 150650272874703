import styled from 'styled-components';

export const Styles = styled.header`
  background-color: var(--cBackground);
  display: flex;
  grid-gap: 1rem;
  grid-template-columns: 200px auto;
  justify-content: space-between;
  left: 0;
  padding: 1rem 2rem;
  position: fixed;
  right: 0;
  transition: box-shadow var(--transMed) ease;
  z-index: 3;

  &.scrolled {
    box-shadow: -1px 5px 11px 0px rgba(0, 0, 0, .1);
  }

  .gatsby-image-wrapper {
    /* Fix Safari bug ignoring overflow: hidden */
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    border-radius: 50%;
    height: 40px;
    margin-right: var(--spacing);
    width: 40px;
  }

  .logo-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;

    a {
      align-items: center;
      color: var(--cPrimary);
      display: flex;
      font-family: var(--serif);
      font-size: var(--h3);
      line-height: var(--h3);
      position: relative;
      text-decoration: none;
      transition: var(--transMed);

      &:hover {
        color: var(--cTertiary);
      }
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 1.75rem;
      }
    }
  }
`;
