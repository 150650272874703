import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation.json';

i18n
  .use(initReactI18next)
  .init({
    // TODO: Define __DEV__ variable
    // debug: __DEV__,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React escapes strings by default
    },
    lng: 'en',
    resources: {
      en: {
        translation: translationEN,
      },
    },
  });

export default i18n;
