module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","display":"standalone","icon":"static/img/favicon.png","name":"Lubos - Personal website.","short_name":"Lubos","start_url":"/","theme_color":"#ffffff","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"24d9f3e0e7a3e27c871bb3bed3b093ac"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-autolink-headers",{"options":{"aliases":{},"classPrefix":"language-","escapeEntities":{},"inlineCodeMarker":null,"languageExtensions":[{"definition":{"superscript_types":{}},"extend":"javascript","insertBefore":{"function":{"superscript_keywords":{}}},"language":"superscript"}],"noInlineHighlight":false,"prompt":{"global":false,"host":"localhost","user":"root"},"showLineNumbers":false},"resolve":"gatsby-remark-prismjs"},{"options":{"maxWidth":2048},"resolve":"gatsby-remark-images"}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
