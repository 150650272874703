import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useTabBlur = () => {
  const { t } = useTranslation();

  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    let originalTitle = '';

    const setTabTitle = (message) => {
      document.title = message;
    };

    const onBlur = () => {
      originalTitle = document.title;

      timer.current = setTimeout(() => {
        setTabTitle(t('tab.blur'));
      }, 2000);
    };

    const onFocus = () => {
      clearTimeout(timer.current);
      setTabTitle(originalTitle);
      originalTitle = '';
    };

    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, [t]);
};
