import { graphql, Link, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { useIntersectionObserver } from '../../constants/observer';
import { Burger } from '../Burger';
import  { Navigation } from '../Navigation';

import { Styles } from './styled';

export function Header() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            title
          }
        }
      }
    `
  );

  const {
    author,
    title,
  } = site.siteMetadata;

  const className = useIntersectionObserver(
    '#scroll-pixel',
    (entries) => entries[0].isIntersecting ? undefined : 'scrolled',
    {
      rootMargin: '50px',
    },
  );

  return ( 
    <Styles className={className}>
      <div className="logo-cont">
        <Link to="/">
          <StaticImage
            alt={author}
            loading="lazy"
            src="../../images/lubos.jpg"
          />
          {title}
        </Link>
      </div>
      <Burger />
      <Navigation />
    </Styles>
   );
}
