// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-templates-journal-index-ts": () => import("./../../../src/templates/Journal/index.ts" /* webpackChunkName: "component---src-templates-journal-index-ts" */),
  "component---src-templates-page-index-ts": () => import("./../../../src/templates/Page/index.ts" /* webpackChunkName: "component---src-templates-page-index-ts" */),
  "component---src-templates-post-index-ts": () => import("./../../../src/templates/Post/index.ts" /* webpackChunkName: "component---src-templates-post-index-ts" */),
  "component---src-templates-tags-index-ts": () => import("./../../../src/templates/Tags/index.ts" /* webpackChunkName: "component---src-templates-tags-index-ts" */)
}

