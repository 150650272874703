import { createGlobalStyle } from "styled-components"
import "@fontsource/lato"
import "@fontsource/playfair-display"

const Typography = createGlobalStyle`
  h1 {
    font-size: var(--h1);
    margin: calc(var(--spacing) * 3) 0;
  }

  p {
    margin: calc(var(--spacing) / 2) 0 var(--spacing) 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--serif);
    font-weight: 400;
  }

  hr,
  blockquote,
  table,
  ol,
  ul,
  .gatsby-resp-image-wrapper {
    margin: calc(var(--spacing) * 2) 0;

    @media (min-width: 768px) {
      margin: calc(var(--spacing) * 3) 0;
    }

    @media (min-width: 1200px) {
      margin: calc(var(--spacing) * 4) 0;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--cSecondary);
  }

  h5,
  h6 {
    color: var(--cTertiary);
    margin: var(--spacing) 0;
  }

  h2 {
    margin: calc(var(--spacing) * 1.5) 0;
    font-size: var(--h2);

    @media (min-width: 768px) {
      margin: calc(var(--spacing) * 2) 0;
    }
  }

  h3 {
    font-size: var(--h3);
  }

  h4 {
    font-size: var(--h4);
    margin: calc(var(--spacing) * 1.5) 0;
  }

  h5 {
    font-size: var(--h5);
  }

  h6 {
    font-size: var(--h6);
  }

  blockquote {
    background-color: rgba(0, 0, 0, .03);
    box-sizing: border-box;
    color: var(--cSecondary);
    font-family: var(--serif);
    font-style: italic;
    padding: calc(var(--spacing) * 2) var(--spacing);
    width: 100%;

    p {
      font-size: var(--h3);
      margin: 0;
    }

    @media (min-width: 768px) {
      padding: calc(var(--spacing) * 3) calc(var(--spacing) * 2);
    }
  }

  hr {
    border: .1rem solid var(--cTertiary);
    opacity: .15;
  }

  ul,
  ol {
    font-size: var(--para);
    padding-left: var(--spacing);

    li {
      margin: var(--spacing) 0;
      padding-left: calc(var(--spacing) / 2);

      &::marker {
        color: var(--cTertiary);
        font-size: .75rem;
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      padding-left: calc(var(--spacing) * 2);

      li {
        padding-left: var(--spacing);
      }
    }
  }

  code {
    border-radius: .25rem;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    font-size: 1rem;
  }

  pre[class*="language-"] {
    margin: calc(var(--spacing) * 2) 0;
  }

  table {
    font-size: var(--para);

    thead {
      background-color: var(--cTertiary);
      color: #fff;
      font-family: var(--serif);
    }

    th,
    td {
      font-weight: 400;
      padding: var(--spacing);
      text-align: left;
    }

    tbody {
      background-color: rgba(0, 0, 0, .03);
    }

    @media (min-width: 768px) {
      th,
      td {
        padding: var(--spacing) calc(var(--spacing) * 1.5);
      }
    }

    @media (min-width: 1200px) {
      th,
      td {
        padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2);
      }
    }
  }

  .gatsby-resp-image-wrapper {
    max-width: 100% !important;
  }
`

export default Typography
