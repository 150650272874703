import 'prismjs/themes/prism-solarizedlight.css';

import { ContextProvider } from './src/components/ContextProvider';
import { Layout } from './src/components/Layout';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <ContextProvider>{element}</ContextProvider>;
}
