import { useState } from 'react';

import { ContextMenu, ContextTheme } from '../../constants/context';

import { Props } from './types';

export function ContextProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isThemeAlt, setIsThemeAlt] = useState(false);

  return (
    <ContextTheme.Provider value={[isThemeAlt, setIsThemeAlt]}>
      <ContextMenu.Provider value={[isOpen, setIsOpen]}>
        {children}
      </ContextMenu.Provider>
    </ContextTheme.Provider>
  )
}
