import { graphql, Link, useStaticQuery } from 'gatsby';

import {
  useMenuItemsLinks,
  useMenuItemsSocial,
} from '../../constants/menu';
import { useQuote } from '../../constants/quotes';
import { LinkMenuItemSocial } from '../LinkMenuItemSocial';

import { Styles } from './styled';

export function Footer() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            authorSite
            title
          }
        }
      }
    `
  );

  const {
    author,
    authorSite,
    title,
  } = site.siteMetadata;

  const menuItemsLinks = useMenuItemsLinks();
  const menuItemsSocial = useMenuItemsSocial();
  const quote = useQuote();

  return (
    <Styles>
      <div className="flex">
        <div className="brand-cont">
          <Link to="/">
            {title}
          </Link>
          <span>
            {quote}
          </span>
        </div>
        <div className="menus-cont">
          <ul className="footer-menu">
            {menuItemsLinks
              .filter((item) => item.title !== 'home')
              .map((item, index) => (
                <li key={`menuItemLink${index}`}>
                  <Link to={item.path}>{item.title}</Link>
                </li>
              ))}
          </ul>
          <ul className="footer-menu">
            {menuItemsSocial.map((item, index) => (
              <li key={`menuItemSocial${index}`}>
                <LinkMenuItemSocial {...item} />
              </li>
            ))}
          </ul>
        </div>
        <div className="copy-cont">
          <ul className="copy">
            <li>
              &copy;
              <a
                href={authorSite}
                rel="noopener noreferrer"
                target="_blank"
              >
                {author}
              </a>
              {new Date().getFullYear()}
            </li>
          </ul>
        </div>
      </div>
    </Styles>
  )
}
