import { Link } from 'gatsby';

import { useContextMenu, useContextTheme } from '../../constants/context';
import { useMenuItemsLinks } from '../../constants/menu';

import { Styles } from './styled';

export function Navigation() {
  const [isOpen, setIsOpen] = useContextMenu();
  const [isThemeAlt, setIsThemeAlt] = useContextTheme();

  const toggleNav = () => {
    setIsOpen((state) => !state);
  };

  const toggleTheme = () => {
    setIsThemeAlt((state) => !state);
  };

  const menuItemsLinks = useMenuItemsLinks();

  return (
    <Styles className={isOpen ? 'open' : 'closed'}>
      <ul>
        {menuItemsLinks.map((item, index) => (
          <li key={`menuItem${index}`} className="nav-link">
            <Link onClick={toggleNav} to={item.path}>
              {item.title}
            </Link>
          </li>
        ))}
        <li className="nav-link">
          <input
            checked={isThemeAlt}
            className="sr-only"
            id="theme-checkbox"
            onChange={toggleTheme}
            type="checkbox"
          />
          <label htmlFor="theme-checkbox">
            <div className="inner">
              <div className="bg" />
              <div className="circle" />
            </div>
            <div className="rays">
              <div className="ray" />
              <div className="ray" />
              <div className="ray" />
              <div className="ray" />
              <div className="ray" />
              <div className="ray" />
              <div className="ray" />
              <div className="ray" />
            </div>
          </label>
        </li>
      </ul>
    </Styles>
  )
}
