import { useTranslation } from 'react-i18next';

export const useMenuItemsLinks = () => {
  const { t } = useTranslation();

  return [
    {
      path: '/',
      title: t('menu.home'),
    },
    {
      path: '/story',
      title: t('menu.story'),
    },
    {
      path: '/portfolio',
      title: t('menu.portfolio'),
    },
    {
      path: '/journal',
      title: t('menu.journal'),
    },
  ];
};

export const useMenuItemsSocial = (): {
  mailto?: string;
  name: string;
  url?: string;
}[] => {
  const { t } = useTranslation();

  return [
    // {
    //   name: 'Calendly',
    //   url: 'https://calendly.com/mrlubos',
    // },
    {
      mailto: 'lmenus@lmen.us',
      name: t('menu.email'),
    },
    {
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/mrlubos',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/mrlubos',
    },
    // {
    //   name: 'YouTube',
    //   url: 'https://youtube.com/channel/UC7GBoz_Cyfk1nwQ_jsYcfyA',
    // },
  ];
};
