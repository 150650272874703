import { useEffect, useState } from 'react';

export const useIntersectionObserver = <State extends unknown>(
  selectors: string,
  callback: (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => State,
  // eslint-disable-next-line no-undef
  options?: IntersectionObserverInit,
) => {
  const [state, setState] = useState<State>();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const nextState = callback(entries, observer);
      setState(nextState);
    }, options);
    const target = document.querySelector(selectors);

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};
