import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  :root {
    --cBackground: #fff;
    --cFooter: #f9f9f9;
    --cPrimary: #2d2828;
    --cSecondary: #3e3e3e;
    --cTertiary: #939393;

    --footerMenuItem: .85rem;
    --h1: 2rem;
    --h2: 1.8rem;
    --h3: 1.5rem;
    --h4: 1.25rem;
    --h5: 1.15rem;
    --h6: 1rem;
    --heavyWeight: 900;
    --para: 1rem;
    --sansSerif: "Lato", sans-serif;
    --serif: "Playfair Display", serif;
    --spacing: 1rem;
    --transMed: .3s;
    --transSlow: .5s;

    @media (min-width: 768px) {
      --footerMenuItem: 1rem;
      --h1: 2.6rem;
      --h2: 2.4rem;
      --h3: 1.75rem;
      --h4: 1.5rem;
      --h5: 1.25rem;
      --h6: 1.1rem;
      --para: 1.1rem;
    }

    @media (min-width: 1200px) {
      --h1: 3rem;
      --h2: 2.8rem;
      --h3: 2rem;
      --h4: 1.75rem;
      --h5: 1.35rem;
      --para: 1.15rem;
    }
  }

  body {
    background-color: var(--cBackground);
    color: var(--cPrimary);
    font-family: var(--sansSerif);
    font-size: var(--para);
    margin: 0;
  }

  html {
    &.switching-theme {
      *:not(#theme-checkbox + label *) {;
        transition: all 0s !important;
      }
    }
  }

  p {
    font-size: var(--para);
    line-height: 1.35;

    @media (min-width: 768px) {
      line-height: 1.5;
    }
  }

  a {
    color: var(--cSecondary);
    text-decoration: underline;

    &.anchor {
      margin-top: -.14em;
      opacity: 0;
      padding-left: 8px;
      padding-right: 8px;
      position: absolute;
      right: 100%;
      transition: opacity .1s ease-in;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover,
    &:visited {
      color: var(--cSecondary);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &[id] {
      &:hover {
        > a {
          opacity: 1;
        }
      }
    }
  }

  #scroll-pixel {
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
    z-index: -1;
  }

  .main-body {
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
    padding: calc(var(--spacing) * 4) calc(var(--spacing) * 2)
      calc(var(--spacing) * 4) calc(var(--spacing) * 2);

    @media (min-width: 768px) {
      max-width: 600px;
      padding: calc(var(--spacing) * 6) calc(var(--spacing) * 2)
        calc(var(--spacing) * 6) calc(var(--spacing) * 2);
    }

    @media (min-width: 1200px) {
      max-width: 860px;
    }
  }

  .nav-link {
    display: inline;
    font-weight: var(--heavyWeight);
    list-style: none;

    a {
      color: var(--cPrimary);
      display: inline-block;
      line-height: 1.75rem;
      position: relative;
      text-decoration: none;
      -webkit-transition: var(--transMed);
      transition: var(--transMed);

      &:hover {
        color: var(--cTertiary);

        &::after {
          left: 100%;
          width: 0;
        }
      }
    }
  }

  .btn {
    background: none;
    border: none;
    color: var(--cPrimary);
    display: inline-block;
    font-family: var(--serif);
    font-size: 1rem;
    line-height: 30px;
    padding: 0;
    position: relative;
    text-decoration: none;

    &-link {
      background-color: transparent;
      border: none;
      color: var(--cSecondary);
      display: flex;
      font-family: var(--serif);
      font-size: var(--h5);
      padding: 0;
      position: relative;
      text-decoration: none;

      &:after {
        background-color: var(--cSecondary);
        bottom: -.25rem;
        content: "";
        display: block;
        height: .1rem;
        left: 0;
        opacity: 1;
        position: absolute;
        transition: opacity var(--transSlow);
        width: 100%;
      }

      &:hover,
      &:focus {
        cursor: pointer;

        &:after {
          opacity: .15;
        }
      }

      &:visited {
        text-decoration: none;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .sr-only {
    border: 0 !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important; /* 3 */
    width: 1px !important;
  }

  .sr-only-focusable:focus,
  .sr-only-focusable:active {
    -webkit-clip-path: none !important;
    clip-path: none !important;
    clip: auto !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    white-space: normal !important;
    width: auto !important;
  }

  .theme-alt {
    :root {
      --cBackground: #1F1D36;
      --cFooter: #3F3351;
      --cPrimary: #E9A6A6;
      --cSecondary: #edc0c0;
      --cTertiary: #f0d1d1;
    }
  }
`

export default GlobalStyles;
