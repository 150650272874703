import { useEffect } from 'react';

import { useContextTheme } from '../../constants/context';
import '../../constants/i18n';
import { useTabBlur } from '../../constants/tab';
import GlobalStyles from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';
import { Footer } from '../Footer';
import { Header } from '../Header';

import { Props } from './types';

export function Layout({ children }: Props) {
  const [, setIsThemeAlt] = useContextTheme();

  useTabBlur();

  useEffect(() => {
    if (window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setIsThemeAlt((state) => !state);
    }
  }, [setIsThemeAlt]);

  return (
    <>
      <GlobalStyles />
      <Typography />
      <Header />
      <div id="scroll-pixel" />
      <main className="main-body">{children}</main>
      <Footer />
    </>
  )
}
