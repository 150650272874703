import styled from 'styled-components';

export const Styles = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--cSecondary);
  display: flex;
  font-family: var(--serif);
  font-size: 1.15rem;
  padding: 0;
  z-index: 3;

  &:focus {
    outline: none;
  }

  > div {
    margin-left: 8px;

    span {
      background-color: var(--cSecondary);
      display: block;
      height: 1px;
      transition: width var(--transMed) ease;
      width: 30px;

      &:nth-child(1) {
        transform: translateY(-8px);
      }

      &:nth-child(3) {
        transform: translateY(8px);
      }
    }
  }

  &.open {
    > div {
      span {
        &:nth-child(2) {
          width: 20px;
        }

        &:nth-child(3) {
          width: 10px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
