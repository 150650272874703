import styled from 'styled-components';

export const Styles = styled.footer`
  --brandMaxWidth: 340px;

  @media (min-width: 600px) {
    --brandMaxWidth: 280px;
  }

  @media (min-width: 768px) {
    --brandMaxWidth: 340px;
  }

  background-color: var(--cFooter);
  color: var(--cSecondary);
  font-family: var(--serif);
  font-style: normal;
  font-weight: 300;
  padding: calc(var(--spacing) * 2);

  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  li {
    font-size: var(--footerMenuItem);
    list-style: none;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 0;
    text-transform: capitalize;

    @media (min-width: 768px) {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  a {
    color: var(--cSecondary);
    text-decoration: none;
    transition: var(--transMed);

    &:hover {
      color: var(--cTertiary);
    }
  }

  @media (min-width: 768px) {
    padding-bottom: calc(var(--spacing) * 4);
    padding-top: calc(var(--spacing) * 4);
  }

  .brand-cont,
  .brand-cont a {
    margin-bottom: calc(var(--spacing) * 2);
  }

  .brand-cont {
    margin-left: 0;
    margin-right: auto;

    a {
      display: inline-block;
      font-family: var(--serif);
      font-size: var(--h3);
      line-height: var(--h3);
    }

    img {
      width: 100%;
    }

    span {
      display: block;
      font-size: var(--footerMenuItem);
      font-style: normal;
      margin-bottom: var(--spacing);
    }
  }

  .footer-menu {
    flex-shrink: 0;
    margin-bottom: calc(var(--spacing) * 2);
    margin-bottom: calc(var(--spacing) * 2);
    margin: 0;
    padding: 0;
    width: 50%;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 375px) {
      margin-bottom: 0;
      width: calc(100% / 3);
    }

    @media (min-width: 768px) {
      flex-basis: 125px;
      flex-grow: 0;
      margin-bottom: 0;

      li {
        padding-left: calc(var(--spacing) / 2);
      }
    }

    @media (min-width: 1200px) {
      flex-basis: 175px;
    }
  }

  .brand-cont,
  .menus-cont,
  .copy-cont {
    font-size: .85rem;
    width: 100%;
  }

  .brand-cont {
    max-width: var(--brandMaxWidth);

    @media (min-width: 600px) {
      flex-basis: var(--brandMaxWidth);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: 50%;
      width: var(--brandMaxWidth);
    }
  }

  .copy-cont {
    a {
      margin-left: calc(var(--spacing) / 3);
      margin-right: calc(var(--spacing) / 3);
    }
  }

  .menus-cont {
    @media (min-width: 375px) {
      display: flex;
    }

    @media (min-width: 600px) {
      display: flex;
      justify-content: flex-end;
      width: calc(100% - var(--brandMaxWidth));
    }
  }

  .copy {
    margin: calc(var(--spacing) * 2) 0 0 0;
    padding: 0;

    @media (min-width: 600px) {
      margin-top: 0;
    }

    li {
      display: inline;
      margin-right: var(--spacing);
    }
  }
`;
